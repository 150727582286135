<template>
  <div class="vg_wrapper">
    <el-card>
      验货开始日期:
      <el-date-picker
        size="small"
        class="topDateRangeSearch vg_mr_8"
        v-model="dateRange1"
        type="daterange"
        value-format="timestamp"
        range-separator="至"
        start-placeholder=""
        end-placeholder=""
      />
      验货结束日期:
      <el-date-picker
        size="small"
        class="topDateRangeSearch vg_mr_8"
        v-model="dateRange2"
        type="daterange"
        value-format="timestamp"
        range-separator="至"
        start-placeholder=""
        end-placeholder=""
      />
      <el-button size="small" type="primary" icon="el-icon-search" @click="getApplicationList()" class="vg_mt_8">查询</el-button>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" class="vd_export" @click="doDelete()" icon="el-icon-delete">删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="warning" size="small" class="vd_export" @click="reapply()">再次申请</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'inspecationmtrb_id'"
        :columns="tableProperties"
        :row-style="setRowStyle"
        :stripe="false"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getApplicationList"
      >
        <template v-slot:mtrb_start="scope">
          <el-tag v-if="scope.row.mtrb_start === 0" size="mini" type="success">正常验货</el-tag>
          <el-tag v-else size="mini" type="danger"> 取消验货</el-tag>
        </template>
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { applicationMtrb } from '@api/modules/applicationMtrb';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/InspectionManagement/InspectionApplicationMtrbManage/applicationMtrb';
import { getArrayIds } from '@assets/js/arrayUtils';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';
import { getStff } from '@api/public';

export default {
  name: 'ApplicationList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      dateRange1: [],
      dateRange2: []
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/inspecationmtrb_edit') {
        this.initData();
      }
    }
  },
  async mounted() {
    await this.getApplicationList();
    this.tableProperties.find(x => x.label === '录入人').options = await getStff('', 'default', '', 'stff_name');
  },
  methods: {
    initData() {
      this.getApplicationList();
    },
    async getApplicationList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange1 || [];
      searchForm.startTime = startTime ? startTime : null;
      searchForm.endTime = endTime ? endTime : null;
      let [startTime2, endTime2] = this.dateRange2 || [];
      searchForm.startTime2 = startTime2 ? startTime2 : null;
      searchForm.endTime2 = endTime2 ? endTime2 : null;
      await applicationMtrb.getApplication(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    buttonRefresh() {
      this.dateRange1 = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getApplicationList();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    dbClickJp(row) {
      this.jump('/inspecationmtrb_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.inspecationmtrb_id
          })
        )
      });
    },
    doDelete() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据！');
      if (this.multiSelection.some(({ status }) => status === 2)) {
        this.$refs.multiTable.clearSelection();
        return this.$message.warning('此单据已生效或者正在审批中,无法删除！');
      }
      this.mBox();
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          applicationMtrb.deleteApplicationByIds(getArrayIds(this.multiSelection, 'inspecationmtrb_id')).then(({ data }) => {
            this.$message.success('删除成功!');
            this.initData();
          });
        })
        .catch(err => {
          console.error(err);
          this.$message.info('取消删除');
        });
    },
    reapply() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据');
      applicationMtrb.getApplicationById({ inspecationmtrb_id: this.multiSelection[0].inspecationmtrb_id }).then(({ data }) => {
        this.$router.push({
          name: 'ApplicationMtrbAdd',
          query: { perm_id: 190, t: Date.now(), order_type: 1 },
          params: { syncForm: data.from, syncData: data.form.inspecationMtrbList }
        });
      });
    },
    setRowStyle({ row }) {
      switch (row.qualification_rate) {
        case '全部合格':
          return { background: '#f0f9eb' };
        case '全部不合格':
          return { background: '#f56c6c' };
        case '存在不合格':
          return { background: '#eb7b81' };
        case '存在空报告':
          return { background: '#f4f4f5' };
        case '存在不合格&空报告':
          return { background: '#fdf6db' };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
